import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';



@Injectable({
  providedIn: 'root'
})

export class EncrDecrService {

  constructor() { }
 
  //The set method is use for encrypt the value.
  set(value){
   
   // console.log(" value...." + value)
    var encrypted = CryptoJS.AES.encrypt(value, 'password#7638*AKTYB').toString(); // 'secret key 123'
    
    return encrypted.toString();
  }

  get(encryptedValaue){

    var bytes  = CryptoJS.AES.decrypt(encryptedValaue, 'password#7638*AKTYB');
    var originalText = bytes.toString(CryptoJS.enc.Utf8);

     return originalText.toString();
   }

}