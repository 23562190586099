<div class="inner-wrapper">
  <section class="inner-section scroll-container">
    <h1 class="information-header">Decrypt Data</h1>
    <!-- <form class="form"> -->
      <div class="row">
        <div class="col col-lg-4 col-md-6 col-sm-6">
          <div class="form-group" [ngClass]="{'required': isSubmitapplicationClicked }">
            <label class="form-label">Encrypted string</label>
            <div class="form-input">
              <input type="text" [(ngModel)]="encriptedString" placeholder="Encrypted string">
            </div>
            <span class="error-msg">*Required field</span>
          </div>
        </div>

      </div>
      <button class="btn btn-next decrypt-btn" (click)="decryptData()">Decrypt</button>
      <div>{{decryptedData}}</div>
    <!-- </form> -->
  </section>
</div>
