import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { CommonService } from '../shared/services/common/common.service';
import { LocalStorageKeys } from '../shared/constants/LocalStorageKeys';
import { HeaderData, LinkedInPersonalDetails, LoggedInUserDetails } from '../shared/Models/Model';
import {DatePipe} from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { environment } from '../../environments/environment';
import { HeaderService} from './header.service';
import { ToastrService } from 'ngx-toastr';
import { BnNgIdleService } from 'bn-ng-idle'; 
import { ApiURLConstants } from '../shared/constants/ApiURLConstants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public showResumeUploader: boolean;
  public showEditAcademicInterest: boolean;
  public resumeData: any;
  public showResumeResultPopup: boolean;
  public localStorageKeys = LocalStorageKeys;
  public headerData: HeaderData;
  public ssoLoginData:any;
  public loggedInUserData: LoggedInUserDetails;
  public showCancelResumeUploader: boolean;
  public showConfirmProfileUpload: boolean;
  public showLogoutConfirmPopup: boolean;
  public viewOption = false; 

  public selectedDegree: string;
  public selectedStartDate: string;

  public userData: any;
  public confirmLogout = false;

  public showOnlyLogoutConfirm: boolean;

  constructor(
    private angularFireAuth: AngularFireAuth,
    public readonly router: Router,
    private readonly commonService: CommonService,
    private datePipe: DatePipe,
    private readonly ngxService: NgxUiLoaderService,
    private readonly headerService: HeaderService,
    private toastr: ToastrService,
    private bnIdle: BnNgIdleService,
    private readonly apiURLConstants: ApiURLConstants,
  ) { }

  /**
   * @description The component initialization
   */
  ngOnInit(): void {

    this.selectedDegree = this.apiURLConstants.getCourseName();
     
    this.ssoLoginData = this.commonService.getFromLocalStorage(this.localStorageKeys.SSO_LOGIN_DATA);
    this.userData = this.commonService.getFromLocalStorage(this.localStorageKeys.USER_DATA);
    this.headerData = {
      name: this.ssoLoginData.displayName,
      image: this.ssoLoginData.photoURL
    };
    //this.updateHeader();

    // this.idleSessionTimeOutSaveInFireStore();
    // this.idleSessionTimeOutLogOut();

  }


  updateHeader(){
    this.loggedInUserData = this.commonService.getFromLocalStorage(this.localStorageKeys.USER_DATA);
    this.selectedDegree = this.loggedInUserData.programName;

    if(this.loggedInUserData.selectedStartDate !== null){
      this.selectedStartDate = this.datePipe.transform(this.loggedInUserData.selectedStartDate.Start_Date__c,"MMM d, y");
    }else{
      this.selectedStartDate =  this.datePipe.transform(this.loggedInUserData.registrationDate,"MMM d, y"); 
    }
  }

  /**
   * @description method to close Edit academic interested popup,
   */
  closeEditAcademicInterest() {
    this.showEditAcademicInterest = false;
    this.updateHeader();
  }

  /**
   * @description method to close Resume upload component,
   * @param event to get the $event from emitter
   */
  closeResumeuploadComponent(event) {
    this.showResumeUploader = false;
    this.showCancelResumeUploader = true;
  }

  agreeUploadCancelConfirmation(event) {
    const userData = this.commonService.getFromLocalStorage(this.localStorageKeys.USER_DATA);
    userData.isLinkedInFileUploaderPopupDisabled = true;
    this.commonService.setToLocalStorage(this.localStorageKeys.USER_DATA, userData);

    this.showCancelResumeUploader = false;
  }

  showConfirmUpload() {
    this.showConfirmProfileUpload = true;
  }

  confirmUploadPopup() {
    this.showConfirmProfileUpload = false;
    this.showResumeUploader = true;
  }

  closeConfirmUploadPopup() {
    this.showConfirmProfileUpload = false;
  }

  closeUploadCancelConfirmation(event){
    this.showResumeUploader = true;
    this.showConfirmProfileUpload = false;
    this.showCancelResumeUploader = false;
  }

  /**
   * @description method to close Resume upload component after resume uploaded,
   * and will redirect to the Show Resume details component,
   * @param resumeData parsed resume data from uploaded pdf resume
   */
  uploadedResumeShowResumeDetailsPopup(resumeData) {
    // if (resumeData) {
    //   this.headerData.showUploadProfile = false;
    // }
    this.resumeData = resumeData;
    this.showResumeUploader = false;
    this.showResumeResultPopup = true;
  }

  /**
   * @description method to close Resume resumt popup,
   */
  closeResumeResultPopup(event) {
    this.showResumeResultPopup = false;
  }

  /**
   * @description method sign out confirm popup,
   */

  showConfirmLogout() {
    this.confirmLogout = true;
  }

  closeConfirmConfirmPopup(){
    this.confirmLogout = false;
  }

  /**
   * @description method sign out from linkedinlogin,
   */
  logOut() {
    this.saveAndExit();
    this.angularFireAuth.signOut().then((asd) => {
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(['login']);
    }, error => {
      console.log('Error signing out:', error);
    });
  }

  headerMenu() {
    if (window.screen.width < 900) {
      this.viewOption = !this.viewOption;
    }

  }
  clearHeaderOption() {
    if (window.screen.width < 900) {
      this.viewOption = false;
    }
  }


  saveAndExit() {
    this.loggedInUserData = this.commonService.getFromLocalStorage(this.localStorageKeys.USER_DATA);
    this.ngxService.start();
    this.headerService.saveAndExit( this.loggedInUserData).subscribe(
      response => {
        console.log(response);
        this.toastr.success('Application data saved successfully');
        this.ngxService.stop();  
      },
      (error) => {
        console.log(error);
        this.toastr.error('Error in saving application' + error);
        this.ngxService.stop();
      }      
    );
  }


  idleSessionTimeOutSaveInFireStore(){
    this.bnIdle.startWatching(300).subscribe((isTimedOut: boolean) => { // Check logged in Or not
      if (isTimedOut) {
        this.userData = this.commonService.getFromLocalStorage(this.localStorageKeys.USER_DATA)
        if(this.userData != null && this.userData.token != null){
          this.toastr.success('Application Data Save Automatically ');
          this.saveAndExit();
        }
       
      }
    });
  }

  idleSessionTimeOutLogOut(){
    this.bnIdle.startWatching(800).subscribe((isTimedOut: boolean) => { // Check logged in Or not
      if (isTimedOut) {
        this.userData = this.commonService.getFromLocalStorage(this.localStorageKeys.USER_DATA)
        if(this.userData != null && this.userData.token != null){
          this.toastr.success('Session expired - LogOut ');
          this.logOut();
        }
       
      }
    });
  }

  /**
   * @description method handle Faq click on mobile page,
   */
  responsiveFaqNavigation() {
    this.router.navigate([]).then(result => {  window.open('/#/faq', '_blank'); });
    this.viewOption = false;
  }

  // lgout with out save :begin
  /**
   * @description method to show save and exit,
   */
  showLogoutWithSave() {
    let showLogout = false;
    const whitelistUrls = ['/student-details/personal-info', '/student-details/adacemic-details',
     '/student-details/review-submit'];
    showLogout = whitelistUrls.includes(this.router.url) ? true : false;
    return showLogout;
  }

  /**
   * @description method to show logout button,
   */
  showLogoutOnlywithoutSave() {
    let showLogout = false;
    const whitelistUrls = ['/accept-offer', '/congratulations', '/admission-accepted', '/admission-denied',
     '/dashboard', '/admission-declined'];
    showLogout = whitelistUrls.includes(this.router.url) ? true : false;
    return showLogout;
  }

  /**
   * @description method to open logout without save cinfirmation popup,
   */
  logoutOnlyWithoutSave() {
    this.showOnlyLogoutConfirm = true;
  }

  /**
   * @description method to close logout without save cinfirmation popup ,
   */
  closeShowOnlyLogoutConfirmPopup(){
    this.showOnlyLogoutConfirm = false;
  }

  /**
   * @description method to logout without save cinfirmation popup ,
   */
  logOutWithoutSave(){
    this.angularFireAuth.signOut().then((asd) => {
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(['login']);
    }, error => {
      console.log('Error signing out:', error);
    });
  }
  // lgout with out save :end

}
