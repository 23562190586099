import { Component, OnInit } from '@angular/core';
import { PersonalInfoService } from '../student-details/personal-info/personal-info.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AcceptOfferService } from './accept-offer.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../shared/services/common/common.service';
import { LocalStorageKeys } from '../shared/constants/LocalStorageKeys';
import { AppConstants } from '../shared/constants/AppConstants';

@Component({
  selector: 'app-accept-offer',
  templateUrl: './accept-offer.component.html',
  styleUrls: ['./accept-offer.component.scss']
})
export class AcceptOfferComponent implements OnInit {
  public type = null;
  public countries = [];
  public states = [];
  coutries: any;
  public isSubmitapplicationClicked: boolean;

  public addressForm: FormGroup;
  public actionTypes = {
    accept: 'accept',
    decline: 'decline'
  };
  public localStorageKeys = LocalStorageKeys;
  public appConstants = AppConstants;
  public unitedStatesCountryId = 'a14o0000001EezgAAC';
  public userData: any;

  constructor(
    private readonly personalInfoService: PersonalInfoService,
    private readonly ngxService: NgxUiLoaderService,
    private router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly acceptOfferService: AcceptOfferService,
    private toastr: ToastrService,
    private commonService: CommonService
  ) { }

  /**
   * @description The component initialization
   */
  ngOnInit(): void {
    this.getCountry();
    this.getState(this.unitedStatesCountryId);
    this.addressForm = this.formBuilder.group({
      addressLineOne: new FormControl(null, [Validators.required]),
      addressLineTwo: new FormControl(null, []),
      country: new FormControl(this.unitedStatesCountryId, [Validators.required]),
      state: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      postalCode: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]+$/)]),
    });
    this.userData = this.commonService.getFromLocalStorage(this.localStorageKeys.USER_DATA);
  }

  /**
   * @description method to decline offer
   */
  declaneOffer() {
    this.isSubmitapplicationClicked = true;
    if (this.type === 'no' && this.addressForm.invalid) {
      return;
    }
    if (this.type === 'no') {
      this.updateAddress(this.actionTypes.decline);
    } else {
      this.acceptOrDeclineAdmission(this.actionTypes.decline);
    }
  }

  /**
   * @description method to accept offer
   */
  acceptOffer() {
    this.isSubmitapplicationClicked = true;
    if (this.type === 'no' && this.addressForm.invalid) {
      return;
    }
    if (this.type === 'no') {
      this.updateAddress(this.actionTypes.accept);
    } else {
      this.acceptOrDeclineAdmission(this.actionTypes.accept);
    }
  }

  /**
   * @description method to update address api call
   * @param action accept or decline offer
   */
  updateAddress(action) {
    this.ngxService.start();
    const uid = this.commonService.getFromLocalStorage(this.localStorageKeys.SSO_LOGIN_DATA).uid;
    const addressForm = this.addressForm.value;
    const address = {
      Billing_AddressLineOne__c: addressForm.addressLineOne,
      Billing_AddressLineTwo__c: addressForm.addressLineTwo,
      Billing_Street__c: '',
      Billing_City__c: addressForm.city,
      Billing_Country__c: addressForm.country,
      Billing_Postal_Code__c: addressForm.postalCode,
      Billing_State_Text__c: addressForm.state,
    };
    this.acceptOfferService.updateAddress(address, uid).subscribe(
      response => {
        if (action === this.actionTypes.accept) {
          this.acceptOrDeclineAdmission(this.actionTypes.accept);
        } else {
          this.acceptOrDeclineAdmission(this.actionTypes.decline);
        }
      },
      (error) => {
        this.toastr.error('Error in update address');
        this.ngxService.stop();
      }
    );
  }

  /**
   * @description method to accept or decline offer
   * @param type accept or decline offer
   */
  acceptOrDeclineAdmission(type) {
    this.ngxService.start();
    const uid = this.commonService.getFromLocalStorage(this.localStorageKeys.SSO_LOGIN_DATA).uid;
    const data = {
      Admissions_Offer_Response__c: type === this.actionTypes.accept ? 'Accept' : 'Decline'
    };
    this.acceptOfferService.acceptAdmission(data, uid).subscribe(
      response => {
        if (response.success === true) {
          if (type === this.actionTypes.accept) {
            this.toastr.success('Accepted offer');
            this.router.navigate(['/admission-accepted']);
          } else {
            this.toastr.success('Declined offer');
            this.router.navigate(['/admission-declined']);
          }
        } else {
          this.toastr.error('Error in accept/decline offer api');
        }
        this.ngxService.stop();
      },
      (error) => {
        this.ngxService.stop();
        if (type === this.actionTypes.accept) {
          this.toastr.error('Error in accept offer api');
        } else {
          this.toastr.error('Error in decline offer api');
        }

      }
    );
  }

  /**
   * @description method to get country list
   */
  public getCountry() {
    this.personalInfoService.getCountry().subscribe((states: any) => {
      this.countries = states;
      this.ngxService.stop();
    },
      (error) => {
        console.log('Error fetching state');
        this.ngxService.stop();
      }
    );
  }

  /**
   * @description method to get states list
   */
  public getState(countryId) {
    this.states = [];
    if (countryId === this.unitedStatesCountryId) {
      this.ngxService.start();
      this.personalInfoService.getState('US').subscribe((states: any) => {
        if (states) {
          this.states = states;
        } else {
          console.log('No State Available');
        }
        this.ngxService.stop();
      },
        (error) => {
          console.log('Error fetching state');
          this.ngxService.stop();
        }
      );
    } else {
      this.addressForm.patchValue({
        state: null,
      });
    }
  }

}
