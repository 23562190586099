import { Component, OnInit } from '@angular/core';
import { EncrDecrService } from '../shared/services/crypto/crypto.service';

@Component({
  selector: 'app-decrypt-data',
  templateUrl: './decrypt-data.component.html',
  styleUrls: ['./decrypt-data.component.scss']
})
export class DecryptDataComponent implements OnInit {
  public isSubmitapplicationClicked: boolean;
  public encriptedString: string;
  public decryptedData: string;
  constructor(
    private EncrDecr: EncrDecrService
  ) { }

  ngOnInit(): void {
  }

  decryptData() {
    this.decryptedData = this.EncrDecr.get(this.encriptedString);
  }

}
