<header class="header">
    <div class="header-top">
        <div class="header-left">
            <img src="assets/images/Logo-other-screens.svg" class="header-logo-img">
        </div>
        <div class="header-right">
            <img *ngIf="headerData.image" class="header-profile-img" (click)="headerMenu()" [src]="headerData.image">
            <span class="user-name">{{headerData.name | titlecase }}</span>
            <!-- <div class="header-logout" (click)="showConfirmLogout()">
                <img src="assets/images/power-logout.svg">
            </div> -->
            <div class="header-options" *ngIf='viewOption'>
                <ul>
                    <!-- <li (click)="viewOption=false; showEditAcademicInterest = !showEditAcademicInterest">
                        <img src="assets/images/edit.svg" class="edit" alt="">Edit Degree & Start Date</li>
                    <li *ngIf="headerData.showUploadProfile" (click)="viewOption=false; showConfirmUpload()">
                        <img src="assets/images/pdf-file.svg" class="pdf" alt="">Upload Profile</li>
                    <li (click)="responsiveFaqNavigation()">
                        <img src="assets/images/help.svg" class="help" alt=""> -->
                    <!-- <a target="_blank" routerLink="/faq">FAQ</a> -->
                    <!-- <a>FAQ</a>
                    </li>
                    <li><img src="assets/images/support.svg" class="support" alt="">
                        <div class="content">
                            Immediate Support
                            <div class="phone">855-649-4970</div>
                        </div>
                    </li> -->
                    <li (click)="viewOption=false; showConfirmLogout()">
                        <img src="assets/images/icon-save-exit.svg" class="logout" alt="">Save & Exit</li>
                </ul>
            </div>
            <div class="header-options-overlay" (click)="clearHeaderOption()" *ngIf='viewOption'></div>
        </div>
    </div>
    <div class="header-bottom">
        <div class="page-header">
            <h5>{{selectedDegree}}</h5>
            <!-- <div class="start-date" >Starting on Sept 3, 2020</div> -->
            <!-- <div class="start-date">Starting on {{selectedStartDate}}</div> -->
            <!-- <div (click)="showEditAcademicInterest = !showEditAcademicInterest" class="btn-edit"><img src="assets/images/icon-edit.svg"></div> -->
        </div>
        <div class="action-wrap">
            <!-- <button class="upload-profile m-r-25" *ngIf="headerData.showUploadProfile" (click)="showConfirmUpload()">
                <img src="assets/images/pdf-file.svg" alt="">Upload Profile</button> -->
            <button class="btn-save-exit exit" (click)="showConfirmLogout()"
                *ngIf="showLogoutWithSave()">
                <img src="assets/images/icon-save-exit.svg" alt="">Save & Exit</button>
            <button class="btn-save-exit exit" (click)="logoutOnlyWithoutSave()"
                *ngIf="showLogoutOnlywithoutSave()">
                <img src="assets/images/icon-save-exit.svg" alt="">Logout</button>    
        </div>
    </div>
</header>

<ng-container *ngIf='confirmLogout'>
    <div class="modal-overlay"></div>
    <div class="modal text-center confirm-logout">
        <div class="modal-header">
            Log Out
        </div>
        <div class="modal-content ">
            <p>All unsaved changes will be lost.</p>
            <p>You can use the <b>Returning Applicant</b> option from the login page to return.</p>
            <p class="text-large"><b>Are you sure you want to Logout?</b></p>
        </div>
        <div class="modal-footer">
            <button class="btn btn-cancel" (click)="logOut()">Yes</button>
            <button class="btn btn-next" (click)="closeConfirmConfirmPopup()">No</button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf='showOnlyLogoutConfirm'>
    <div class="modal-overlay"></div>
    <div class="modal text-center confirm-logout">
        <div class="modal-header">
            Log Out
        </div>
        <div class="modal-content ">
            <p class="text-large"><b>Are you sure you want to Logout?</b></p>
        </div>
        <div class="modal-footer">
            <button class="btn btn-cancel" (click)="logOutWithoutSave()">Yes</button>
            <button class="btn btn-next" (click)="closeShowOnlyLogoutConfirmPopup()">No</button>
        </div>
    </div>
</ng-container>