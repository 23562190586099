import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth/auth.service';
import { CommonService } from '../shared/services/common/common.service';
import { LocalStorageKeys } from '../shared/constants/LocalStorageKeys';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {
  public localStorageKeys = LocalStorageKeys;

  constructor(
    private router: Router,
    private auth: AuthService,
    private readonly commonService: CommonService, ) { }

  /**
   * @description check if the current route should be enabled based on the user authentication.
   * @returns info if the user is authenticated or not.
   */
  canActivateChild(): Observable<boolean> {
    // const nodeAccessToken = this.commonService.getFromLocalStorage(this.localStorageKeys.USER_DATA);
    return this.auth.authInfo
      .pipe(
        take(1),
        map(user => !!user),
        tap(signedIn => {
          // if (!signedIn || !nodeAccessToken || !nodeAccessToken.token) {
          if (!signedIn) {
            this.router.navigate(['login']);
          }
        })
      );
  }

  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return true;
  // }

  // /**
  //  * @description check if the current route should be enabled based on the user authentication.
  //  * @returns info if the user is authenticated or not.
  //  */
  // canActivate(): Observable<boolean> {
  //   const nodeAccessToken = this.commonService.getFromLocalStorage(this.localStorageKeys.USER_DATA);
  //   return this.auth.authInfo
  //     .pipe(
  //       take(1),
  //       map(user => !!user),
  //       tap(signedIn => {
  //         if (!signedIn || !nodeAccessToken || !nodeAccessToken.token) {
  //           this.router.navigate(['login']);
  //         }
  //       })
  //     );
  // }

}
