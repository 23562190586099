import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { AuthService } from '../shared/services/auth/auth.service';
import { environment } from '../../environments/environment';


// import { AuthService } from '../auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  whiteListEndPoints = ['login'];
  constructor(
    private auth: AuthService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    /* Get the auth token from the service. */
    const authToken = this.auth.getAuthorizationToken();
    // const baseUrl = this.auth.getBaseUrl(req.url);
    // const authToken = 'test auth';

    /*
      The verbose way:
      Clone the request and replace the original headers with
      cloned headers, updated with the authorization.
      const authReq = req.clone({
        headers: req.headers.set('Authorization', authToken)
      });
    */
    /*
      Clone the request and set the new header in one step.
      const authReq = req.clone({ setHeaders: { Authorization: authToken } });
    */

    // let authReq = req.clone();
    // if (baseUrl + '/app/' === environment.nodeApiUrl) {
    //   const endPoint = this.auth.getEndPoint(req.url);
    //   if (this.whiteListEndPoints.includes(endPoint)) {
    //     const authTokenForNode = this.auth.getAuthorizationTokenForNode();
    //     authReq = req.clone({ setHeaders: { Authorization: 'Bearer' + authTokenForNode } });
    //   }
    // }
    let authReq = req.clone();
    const endPoint = this.auth.getEndPoint(req.url);
    if (!this.whiteListEndPoints.includes(endPoint)) {
      if (endPoint === 'cloud_pdf_reader' || endPoint === 'pdf_custom_auth') {

      } else {
        const authTokenForNode = this.auth.getAuthorizationTokenForNode();
        authReq = req.clone({ setHeaders: { Authorization: 'Bearer ' + authTokenForNode } });
      }
    }

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
