<div class="inner-wrapper container-wth-scroll" [ngClass]="{'align-top': type =='0'}">
  <div class="success-msg-wrap">
    <img class="success-icon" src="assets/images/W-logo.svg" alt="">
    <h3>Congratulations!</h3>
    <p class="text-center">It is with great pleasure to offer you admission to Walden University, <br>
      for the <b>{{appConstants.PRODUCT_NAME}}</b> program.</p>
    <div class="ask-qst">
      <p>Do you still live in <b>{{userData.formData.personalInformation.state || ''}}?</b></p>
      <div class="col-12 m-t-20">
        <label class="radio-container">
            <input name="type" [(ngModel)]="type" type="radio" [value]="'yes'" />
          <span class="checkmark"></span>
          <span class="radio-label">Yes</span>
        </label>
        <label class="radio-container">
            <input name="type" [(ngModel)]="type" type="radio" [value]="'no'"/>
          <span class="checkmark"></span>
          <span class="radio-label">No</span>
        </label>
      </div>
    </div>
    <ng-container *ngIf = "type =='no'">
      <div class="text-left" >
        <h3 class="information-subheader">Update Your Address Details</h3>
        <form class="form" [formGroup]="addressForm">
          <div class="row">
            <div class="col-6 col-sm-12">
              <div class="form-group" [ngClass]="{'required': isSubmitapplicationClicked && 
                  addressForm.controls.addressLineOne.errors && 
                  addressForm.controls.addressLineOne.errors.required}">
                <label class="form-label">Address Line 1*</label>
                <div class="form-input">
                  <input type="text" formControlName="addressLineOne" placeholder="Enter your Address">
                </div>
                <span class="error-msg">*Required field</span>
              </div>
            </div>
            <div class="col-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Address Line 2*</label>
                <div class="form-input">
                  <input type="text" formControlName="addressLineTwo" placeholder="Enter your Address">
                </div>
                <span class="error-msg">*Required field</span>
              </div>
            </div>
            <div class="col-4 col-sm-12">
              <div class="form-group" [ngClass]="{'required': isSubmitapplicationClicked && 
                addressForm.controls.country.errors && 
                addressForm.controls.country.errors.required}">
                <label class="form-label">Country*</label>
                <div class="form-input">
                  <ng-select class="custom" formControlName="country" placeholder="Select Country" 
                  (change)="getState(addressForm.value.country)"
                  [items]="countries" bindLabel="Name" bindValue="Id" ></ng-select>
                  <span class="error-msg">*Required field</span>
                </div>
              </div>
            </div>
            <div class="col-4 col-sm-12">
              <div class="form-group" [ngClass]="{'required': isSubmitapplicationClicked && 
                addressForm.controls.state.errors && 
                addressForm.controls.state.errors.required}"
                *ngIf="addressForm.value.country === unitedStatesCountryId">
                <label class="form-label">State*</label>
                <div class="form-input">
                  <ng-select class="custom" formControlName="state" [items]="states" 
                    bindLabel="Name" bindValue="Name"></ng-select>
                </div>
                <span class="error-msg">*Required field</span>
              </div>
              <div class="form-group" [ngClass]="{'required': isSubmitapplicationClicked && 
                addressForm.controls.state.errors && 
                addressForm.controls.state.errors.required}"
                *ngIf="addressForm.value.country !== unitedStatesCountryId">
                <label class="form-label">State*</label>
                <div class="form-input">
                  <input type="text" formControlName="state" placeholder="Enter State">
                </div>
                <span class="error-msg">*Required field</span>
              </div>
            </div>
            <div class="col-4 col-sm-12">
              <div class="form-group" [ngClass]="{'required': isSubmitapplicationClicked && 
                addressForm.controls.city.errors && 
                addressForm.controls.city.errors.required}">
                <label class="form-label">City*</label>
                <div class="form-input">
                  <input type="text" formControlName="city" placeholder="Enter City">
                </div>
                <span class="error-msg">*Required field</span>
              </div>
            </div>
            <div class="col-4 col-sm-12">
              <div class="form-group" [ngClass]="{'required': isSubmitapplicationClicked && 
                addressForm.controls.postalCode.errors && 
                addressForm.controls.postalCode.errors.required}">
                <label class="form-label">Zip/Postal Code*</label>
                <div class="form-input">
                  <input type="text" formControlName="postalCode" placeholder="Enter Zip/Postal code">
                </div>
                <span class="error-msg">*Required field</span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ng-container>
    <div class="btn-wrapper">
      <button class="btn btn-save-exit" (click)="declaneOffer()">Decline</button>
      <button class="btn btn-next" [disabled]="!type" (click)="acceptOffer()">Accept</button>
    </div>
  </div>
</div>
