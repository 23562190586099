import { Component, AfterViewInit, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'zbridge';
  public showSideNav: boolean;
  public hideSideNavUrls = ['/', '/login'];

  constructor(
    public readonly router: Router
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        // this.hideSideNav = (e.url !== '/login' && e.url !== '/') ? true : false;
        // this.showSideNav = (e.url !== '/login' && e.url !== '/' && e.url !== '/faq') ? true : false;
        this.showSideNav = (this.hideSideNavUrls.indexOf(e.url) >= 0) ? false : true;
      }
    });
  }
  changeContainer() {
    let addClass = false;
    const whitelistUrls = ['/accept-offer', '/congratulations', '/admission-accepted', '/admission-denied',
      '/admission-declined'];
    addClass = whitelistUrls.includes(this.router.url) ? true : false;
    return addClass;
  }

}
