import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import * as firebase from 'firebase';
import { AngularFireAuth } from '@angular/fire/auth';
import { CommonService } from '../common/common.service';
import { LocalStorageKeys } from '../../constants/LocalStorageKeys';

@Injectable()
export class AuthService {
  public authInfo: Observable<firebase.User>;
  public userData: any;
  public localStorageKeys = LocalStorageKeys;

  constructor(
    private afAuth: AngularFireAuth,
    private commonService: CommonService
  ) {
    this.authInfo = this.afAuth.authState;
    this.userData = this.commonService.getFromLocalStorage(this.localStorageKeys.USER_DATA);
  }

  /**
   * @description method to get token,
   */
  getAuthorizationToken() {
    return 'some-auth-token';
  }

  /**
   * @description method get auth token for node api calls from localhost,
   */
  getAuthorizationTokenForNode() {
    this.userData = this.commonService.getFromLocalStorage(this.localStorageKeys.SSO_LOGIN_DATA);
    return this.userData ? this.userData.stsTokenManager.accessToken : '';
  }

  /**
   * @description method to get the base url from req url,
   * @param url end point complete url from req
   */
  getBaseUrl(url) {
    const rulArray = url.split('/');
    return rulArray.length > 0 ? `${rulArray[0]}/${rulArray[1]}/${rulArray[2]}` : '';
  }

  /**
   * @description method to ge the getEndPoint,
   * @param url request url,
   */
  getEndPoint(url) {
    const urlArrray = url.split('/');
    return urlArrray[urlArrray.length - 1];
  }
}
