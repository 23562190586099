import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { ApiURLConstants } from '../shared/constants/ApiURLConstants';
import { Observable } from 'rxjs';

@Injectable()
export class HeaderService {

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly http: HttpClient,
    private readonly apiURLConstants: ApiURLConstants,
  ) { }

   
  saveAndExit(user) {
    const url = this.apiURLConstants.saveAndExitUrl();
    return this.http.put(url,user);
  }


}
