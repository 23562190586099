<div class="footer-wrapper">
    <div class="container">
        <div class="left-title">
            <a href="https://www.waldenu.edu/-/media/Walden/files/legal/2019-walden-annual-security-report-final" 
            target="_blank"  
            rel="noopener noreferrer">
                Annual Security Report
            </a>
            <span>|</span>
            <a href="https://www.waldenu.edu/legal#privacy"  
            target="_blank"  
            rel="noopener noreferrer">
                Privacy Policy
            </a>
        </div>
        <div class="mid-title">
            <div class="heading">Contact Walden</div>
            <div class="subheading">
                855 713 0581
                <span>|</span>
                contact@waldenu.edu
            </div>
        </div>
        <div class="right-title">
            © 2020 Walden University. All Rights Reserved
        </div>
    </div>
</div>