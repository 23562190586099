import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageKeys } from '../shared/constants/LocalStorageKeys';
import { AuthService } from '../shared/services/auth/auth.service';
import { CommonService } from '../shared/services/common/common.service';
import { take, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ComponentAuthGuard implements CanActivate {
  public localStorageKeys = LocalStorageKeys;

  constructor(
    private router: Router,
    private auth: AuthService,
    private readonly commonService: CommonService, ) { }

  /**
   * @description check if the current route should be enabled based on the user authentication.
   * @returns info if the user is authenticated or not.
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.authInfo
      .pipe(
        take(1),
        map(user => !!user),
        tap(signedIn => {
          // if (!signedIn || !nodeAccessToken || !nodeAccessToken.token) {
          if (!signedIn) {
            this.router.navigate(['login']);
          }
        })
      );
  }

}
