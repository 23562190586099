import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-congratulation-submit',
  templateUrl: './congratulation-submit.component.html',
  styleUrls: ['./congratulation-submit.component.scss']
})
export class CongratulationSubmitComponent implements OnInit {

  constructor(  private readonly ngxService: NgxUiLoaderService,) { }

  ngOnInit(): void {
    // this.ngxService.start();
    // this.ngxService.stop();
  }

}
