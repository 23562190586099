<div class="inner-wrapper fit-container">
    <div class="success-msg-wrap">
        <img class="success-icon" src="assets/images/W-logo.svg" alt="">
        <div class="text-justify">
            <h3>Congratulations {{data.name}}, and Welcome to Walden University!</h3>
            <p>We’re excited that you have accepted Walden’s offer of admission to the <b>{{appConstants.PRODUCT_NAME}}</b> program.</p>           
            <p>You will soon receive an e-mail from newstudentsetup@mail.waldenu.edu with instructions on setting up your Walden e-mail. In addition, you will receive information regarding how to prepare to start your program.</p>                
            <p>Please contact your Enrollment Specialist if you have any questions, and again, welcome to Walden University!</p>
        </div>            
    </div>
</div>