<div class="inner-wrapper fit-container">
    <div class="success-msg-wrap">
        <img class="success-icon" src="assets/images/W-logo.svg" alt="">
        <div class="text-justify">
            <p>Dear {{data.name}},</p>
            <p>Thank you for applying to Walden University. After completing a careful review of your application and supporting documentation, we regret that we are unable to offer you admission to Walden.</p>
            <p>We wish you success in your endeavors, and if you believe your future education needs could still be served by Walden, we look forward to hearing from you.</p>
            <p>Sincerely, <br>
                Devon W. Edmund <br>
                Vice President, Academic Administration<br>
                Walden University</p>  
        </div>            
    </div>
</div>