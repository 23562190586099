import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logout-confirm-popup',
  templateUrl: './logout-confirm-popup.component.html',
  styleUrls: ['./logout-confirm-popup.component.scss']
})
export class LogoutConfirmPopupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
