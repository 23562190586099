<app-header *ngIf="showSideNav"></app-header>
<div class="page-wrapper" [ngClass]="{'full-width': !showSideNav}">
    <div class="content-container" 
    [ngClass]="{
    'message-container': changeContainer(),
    'content-container-inside': showSideNav}"
    >
        <div class="content-section">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<app-footer></app-footer>

<ngx-ui-loader bgsPosition="center-center"></ngx-ui-loader>