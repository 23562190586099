import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiURLConstants } from '../shared/constants/ApiURLConstants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AcceptOfferService {

  constructor(
    private readonly apiURLConstants: ApiURLConstants,
    private readonly http: HttpClient,
  ) { }

  /**
   * @description method set user new address
   * @param user request body
   */
  updateAddress(address, uid): Observable<any> {
    const url = this.apiURLConstants.acceptDeclineUpdateAddressUrl() + 'uid=' + uid;
    return this.http.patch<any>(url, address);
  }

  /**
   * @description method set user new address
   * @param user request body
   */
  acceptAdmission(data, uid): Observable<any> {
    const url = this.apiURLConstants.acceptDeclineAcceptAdmissionUrl() + 'uid=' + uid;
    return this.http.patch<any>(url, data);
  }
}
