import { Component, OnInit } from '@angular/core';
import { CommonService } from '../shared/services/common/common.service';
import { LocalStorageKeys } from '../shared/constants/LocalStorageKeys';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-admission-denied',
  templateUrl: './admission-denied.component.html',
  styleUrls: ['./admission-denied.component.scss']
})
export class AdmissionDeniedComponent implements OnInit {
  public userData: any;
  public data: any;
  public localStorageKeys = LocalStorageKeys;

  constructor(
    private commonService: CommonService,
    private readonly ngxService: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
    this.ngxService.stop();
    this.userData = this.commonService.getFromLocalStorage(this.localStorageKeys.USER_DATA);
    this.data = {
      name: this.userData.formData.personalInformation.firstName
    };
  }
}
