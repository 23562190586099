import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes, NoPreloading, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './core/auth.guard';
import { CongratulationSubmitComponent } from './congratulation-submit/congratulation-submit.component';
import { AcceptOfferComponent } from './accept-offer/accept-offer.component';
import { AdmissionDeniedComponent } from './admission-denied/admission-denied.component';
import { AdmissionAcceptedComponent } from './admission-accepted/admission-accepted.component';
import { ComponentAuthGuard } from './core/component-auth.guard';
import { DecryptDataComponent } from './decrypt-data/decrypt-data.component';
import { AdmissionDeclinedComponent } from './admission-declined/admission-declined.component';

const appRoutes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./linkedin-login/linkedin-login.module').then(m => m.LinkedinLoginModule),
  },
  {
    path: 'student-details',
    loadChildren: () => import('./student-details/student-details.module').then(m => m.StudentDetailsModule),
    canActivateChild: [AuthGuard]
   },
   {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivateChild: [AuthGuard]
   },
   {
     path: 'congratulations',
     component: CongratulationSubmitComponent,
     canActivate: [ComponentAuthGuard]
   },
   {
     path: 'accept-offer',
     component: AcceptOfferComponent,
     canActivate: [ComponentAuthGuard]
   },
   {
     path: 'admission-denied',
     component: AdmissionDeniedComponent,
     canActivate: [ComponentAuthGuard]
   },
   {
     path: 'admission-accepted',
     component: AdmissionAcceptedComponent,
     canActivate: [ComponentAuthGuard]
   },
   {
    path: 'decrypt-data',
    component: DecryptDataComponent,
    // canActivate: [ComponentAuthGuard]
  },
  {
    path: 'admission-declined',
    component: AdmissionDeclinedComponent,
    canActivate: [ComponentAuthGuard]
  },
  // {
  //   path: 'background-info',
  //   loadChildren: () => import('./background-info/background-info.module').then(m => m.BackgroundInfoModule),
  //   canActivateChild: [AuthGuard]
  // },
  // {
  //   path: 'employment-academics',
  //   loadChildren: () => import('./employment-academics/employment-academics.module').then(m => m.EmploymentAcademicsModule),
  //   canActivateChild: [AuthGuard]
  // },
  // {
  //   path: 'review-submit',
  //   loadChildren: () => import('./student-details/review-submit/review-submit.module').then(m => m.ReviewSubmitModule),
  //   canActivateChild: [AuthGuard]
  // },
  // {
  //   path: 'faq',
  //   loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule),
  // },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(
      appRoutes,
      {
        useHash: true,
        enableTracing: false, // <-- debugging purposes only
        preloadingStrategy: NoPreloading,
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
