
export const environment = {
  production: true,
  apiUrl: 'http://demo4350834.mockable.io/',
  nodeApiUrl: 'https://us-central1-walden-undergradbridge-prod.cloudfunctions.net/app/',
  pdfParserURL: 'https://us-central1-walden-quickapplication-prod.cloudfunctions.net/prod_parser',
  redirectionURL: 'https://p2full-waldenu.cs36.force.com/Walden/services/auth/oauth/RocketOLALinkedInProvider',
  productCode: '01t2G000006eCv6',
  productName: 'Application for Bachelor of Science in Interdisciplinary Studies',
  authService: 'linkedin.com',
  firebaseConfig: {
    apiKey: "AIzaSyDjE_bwHx6Y8J4Pj7rx6irf3qWoFlZaEvo",
   authDomain: "walden-undergradbridge-prod.firebaseapp.com",
   databaseURL: "https://walden-undergradbridge-prod.firebaseio.com",
   projectId: "walden-undergradbridge-prod",
   storageBucket: "walden-undergradbridge-prod.appspot.com",
   messagingSenderId: "774203221620",
   appId: "1:774203221620:web:76f51eaa6ae7277fd39321",
   measurementId: "G-H2X205Q138"
  },
  dashbordBaseUrl: 'https://myundergradbridge.waldenu.edu/'
};

