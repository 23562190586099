import { Component, OnInit } from '@angular/core';
import { CommonService } from '../shared/services/common/common.service';
import { LocalStorageKeys } from '../shared/constants/LocalStorageKeys';
import { AppConstants } from '../shared/constants/AppConstants';

@Component({
  selector: 'app-admission-accepted',
  templateUrl: './admission-accepted.component.html',
  styleUrls: ['./admission-accepted.component.scss']
})
export class AdmissionAcceptedComponent implements OnInit {

  public localStorageKeys = LocalStorageKeys;
  public userData: any;
  public data: any;
  public appConstants = AppConstants;

  constructor(
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.userData = this.commonService.getFromLocalStorage(this.localStorageKeys.USER_DATA);
    this.data = {
      name: this.userData.formData.personalInformation.firstName
    };
  }

}
