import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiURLConstants } from '../../shared/constants/ApiURLConstants';
import { AngularFirestore } from '@angular/fire/firestore';


@Injectable()
export class PersonalInfoService {

  constructor(
    private readonly http: HttpClient,
    private readonly apiURLConstants: ApiURLConstants,
    private readonly firestore: AngularFirestore
  ) { }

  getdata(no): Observable<any> {
    const url = this.apiURLConstants.getTransactionDashboardUrl();
    return this.http.get<any>(url);
  }


  getGender() {
    return [{
      value: 'Female',
      key: 'Female'
    },
    {
      value: 'Male',
      key: 'Male'
    } ]
  }


  getEthinicity() {
    return [{
      value: 'Asian',
      key: 'Asian'
    },
    {
      value: 'Black or African American',
      key: 'Black or African American'
    },
    {
      value: 'Native Hawaiian or Pacific Islander',
      key: 'Native Hawaiian or Pacific Islander'
    },
    {
      value: 'White',
      key: 'White'
    },
    {
      value: 'American Indian or Alaska Native',
      key: 'American Indian or Alaska Native'
    }
  
  ]
  }

  getCountry(){
    return this.firestore
      .collection('countries', ref => ref).valueChanges();
  }

  public getState(country) {
  
    return this.firestore
      .collection('states', ref => ref.where('Country_ISO_Code__c', '==', country)).valueChanges();
  }
 
  /**
   * @description hardcoded sample resume data
   */
  getParsedResumedata() {
    return {
      personalInformation: {
        basicInfo: {
          firstName: '',
          middleName: '',
          lastName: '',
          suffix: '',
          emailaddr: '',
          mobileNo: null,
          homePhone: null,
          preferedPhoneType: 'mobile'
        },
        address: {
          address: 'asdasd',
          suitApt: 'asd',
          country: 'US',
          state: 'asdasd',
          city: 'asdasda',
          postalCode: 213324,
          isMailingAddrDiff: false,
          mailingAddress: {
            address: 'asdasd',
            suitApt: 'asd',
            country: 'US',
            state: 'asdasd',
            city: 'asdasda',
            postalCode: 213324,
          }
        }
      },
      backgroundInfo: {
        basicDetails: {
          dateOfBirth: '10 - 03 - 1995',
          gender: 'male',
          citizenship: 'usa',
          isEnglishYourPrimaryLanguage: 'yes',
          motivationForContinuingYourEducation: 'select the reason'
        },
        militaryBasedQuestions: {
          areYouOrYourSpousePartOfTheMilitaryService: 'yes/no',
          slectedOptionForYes: {
            iAmServicemanOfMilitary: true,
            iAmVeteranOfMilitary: false,
            mySpouseIsServicemanOrVeteranOfMilitary: false,

          }
        },
        educationFunding: {
          intendOnFundingYourEducation: {
            outOfPocket: false,
            financialAid: false,
            companyTuitionReimbursement: false,
            militaryBenefits: false,
            paymentPlan: false,
            personalLoan: false,
            scholarship: false,
            unsure: false
          },
          socialSecurityNoForFinacialAid: '213123123',
          nameOfCompanyForCompanyTuitionReimbursement: 'Google'
        },
        ethnicityBasedQuestions: {
          hispanicOrLatino: 'yes',
          ethnicGroup: {
            asian: false,
            blackOrAfricanAmerican: false,
            nativeHawaiianOrPacificIslander: false,
            white: false,
            americanIndianOrAlaskaNative: true
          }
        }
      },
      employmentAndAcademic: {
        currentemployerinformation: {
          isWillingToProvideMyCurrentEmpInfo: true,
          currentemployer: 'google',
          addadditionalemployer: [
            'facebook',
            'flipkart'
          ]
        },
        academichistory: [
          {
            highestlevelofeducation: 'bachelors degree',
            isCompletedAtWaldenUniversity: 'yes/no',
            nameOfInstitution: 'oxforduniversity',
            country: 'usa',
            major: 'computer',
            graduationYear: 2003,
            gpa: '2.0-2.45orCtoC+',
            isSubmittingtranscript: true,
            submitTranscriptForThisAcademic: true,
            transcriptOption: {
              iWillSubmitUnofficialTranscript: true,
              iHaveRquestedOfficialTranscript: false
            },
            isTranscriptUploaded: true,
            isNameDiffersIntranscript: true,
            nameInTranscript: 'amal',
            isOfficialTranscriptNeedAcknowledged: false,
            isOfficialTranscriptSendAcknowledged: false
          }
        ],
        transferOfCredit: {
          ihavetakencourseforcollegecredit: false,
          ihaveaprofessionalcertificationrelevanttomydesiredprogram: false,
          ihavepassedrelevantexaminationsforgraduationLevelsubjects: false,
          noneoftheabove: false
        },
        additionalrequireddocuments: {
          isresumeUploaded: false
        }
      }
    };
  }
}
