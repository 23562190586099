import { Injectable } from '@angular/core';
import { LocalStorageKeys } from '../../constants/LocalStorageKeys';
import { AppConstants } from '../../constants/AppConstants';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  localStorageKeys = LocalStorageKeys;
  appConstants = AppConstants;

  public updateProgramNameSubject = new Subject<object>();
  public updateProgramNameEvent = this.updateProgramNameSubject.asObservable();

  constructor() { }

  /**
   * @description Function to set data to local storage
   * @param key key to store data
   * @param data data to be stored
   */
  setToLocalStorage(key: string, data: any) {
    if (data) {
      data = JSON.stringify(data);
      localStorage.setItem(key, data);
    }
  }
  /**
   * @description Function to get data from local storage
   * @param key key to access the data
   */
  getFromLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  /**
   * @description Function to show or hide additoinal req doc in 
   * Employment and academics section
   */
  showAdditionalReqDocuments() {
    return true;
  }

  /**
   * @description Function to add next data to the Subject
   * @param data next data to the Subject object
   */
  public updateProgramNameInAddDoc(data: any) {
    this.updateProgramNameSubject.next(data);
  }

}
