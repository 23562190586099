import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class ApiURLConstants {

    /**
     * @desc method to return return LinkedIn projection url.
     */
    getLinkedinProjectionUrl(): string {
        return `/v2/me?projection=(id,firstName,lastName,profilePicture(displayImage~:playableStreams))`;
    }

    /**
     * @desc method to return fetch transaction dashboard data url.
     */
    getTransactionDashboardUrl(): string {
        return `${environment.apiUrl}testRocketMortgage`;
    }

    /**
     * @desc method to return fetch transaction dashboard data url.
     */
    getBackgrounfInfoUrl(): string {
        return `${environment.apiUrl}rocketMortgage/backgroundInfo`;
    }

    /**
     * @desc method to return fetch startDate.
     */
    getStartDateUrl(): string {
        return `${environment.nodeApiUrl}api/startdate?`;
    }

    /**
     * @desc method to return fetch get employment resume upload url.
     */
    getUploadFileUrl(): string {
        return `${environment.nodeApiUrl}api/user/uploads`;
    }

    /**
     * @desc method to return fetch user details after login.
     */
    setUserDetailsUrl(): string {
        return `${environment.nodeApiUrl}api/user/login`;
    }

    /**
     * @desc method to return fetch disposition data after firebase login.
     */
    getDispositionDataUrl(): string {
        return `${environment.nodeApiUrl}api/user/dispositionstatus`;
    }

    saveAndExitUrl(): string {
        return `${environment.nodeApiUrl}api/user`;
    }

    reviewAndSubmitUrl(): string {
        return `${environment.nodeApiUrl}api/user/reviewsubmit`;
    }

    getPdfToTextPythonApi(): string {
        // return `https://us-central1-walden-quickapplication-dev.cloudfunctions.net/cloud_pdf_reader`;

        return `${environment.pdfParserURL}`;
    }

    updateStartDateUrl(): string {
        return `${environment.nodeApiUrl}api/user/startdate`;
    }

    public getRedirectionURL(): string {
        return `${environment.redirectionURL}`;
    }

     public getProductId(): string {
        return `${environment.productCode}`;
    }

    getCourseName() {
        return `${environment.productName}`;
    }

    /** Amal new code:begin */
    /**
     * @desc method to update adress from accept decline page.
     */
    acceptDeclineUpdateAddressUrl(): string {
        return `${environment.nodeApiUrl}api/user/updateAddress?`;
    }
    /**
     * @desc method to update adress from accept decline page.
     */
    acceptDeclineAcceptAdmissionUrl(): string {
        return `${environment.nodeApiUrl}api/user/acceptdecline?`;
    }
    /** Amal new code:end */

}
