import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HeaderComponent } from './header/header.component';
import { ApiURLConstants } from './shared/constants/ApiURLConstants';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './http-interceptors';
import { AuthService } from './shared/services/auth/auth.service';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
//import { PercentageCalculatorService } from './shared/services/percentage-calculator/percentage-calculator.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { HeaderService } from './header/header.service';
import { LogoutConfirmPopupComponent } from './header/logout-confirm-popup/logout-confirm-popup.component';
import { BnNgIdleService } from 'bn-ng-idle'; 
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { CongratulationSubmitComponent } from './congratulation-submit/congratulation-submit.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import { AcceptOfferComponent } from './accept-offer/accept-offer.component';
import { PersonalInfoService } from './student-details/personal-info/personal-info.service';
import { AdmissionDeniedComponent } from './admission-denied/admission-denied.component';
import { AdmissionAcceptedComponent } from './admission-accepted/admission-accepted.component';
import { DecryptDataComponent } from './decrypt-data/decrypt-data.component';
import { AdmissionDeclinedComponent } from './admission-declined/admission-declined.component';
import { FooterComponent } from './footer/footer.component';
import {MatDatepickerModule} from '@angular/material/datepicker';


@NgModule({
  declarations: [
    AppComponent,
      HeaderComponent,
       LogoutConfirmPopupComponent,
       CongratulationSubmitComponent,
       AcceptOfferComponent,
       AdmissionDeniedComponent,
       AdmissionAcceptedComponent,
       DecryptDataComponent,
       AdmissionDeclinedComponent,
       FooterComponent,
  ],
  imports: [
    // MyDatePickerModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxUiLoaderModule,
    AngularFireModule.initializeApp(environment.firebaseConfig), // imports firebase/app needed for everything
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features,
    AngularFireAnalyticsModule, // imports Analytics related events
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    PdfViewerModule,
    MatDatepickerModule,
    // ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' })
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-bottom-right',
      closeButton: true,
      // disableTimeOut: true,
      // preventDuplicates: true,
    }) // ToastrModule added
  ],
  providers: [ApiURLConstants, AuthService,
    PersonalInfoService,
    httpInterceptorProviders, // ucomment it when headerws required in node api calls
    DatePipe, HeaderService, BnNgIdleService], //PercentageCalculatorService,
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
