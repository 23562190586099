export const LocalStorageKeys = {
    LINKEDIN_DATA: 'linkedInData',
    RESUME_DATA: 'resumeData', // will be removed
    FORM_DATA: 'formData',
    IS_SUBMIT_APPLICATION_CLICKED: 'isSubmitapplicationClicked',
    USER_DATA: 'userData',
    TERMS_CHECK: 'termsCheck',

    IS_SIGNING_ON: 'isSigningOn',
    SSO_LOGIN_DATA: 'ssoLoginData'
};
